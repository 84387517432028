// Variables
//
// These variables will override anything set in the default Bootstrap library.
// We can also set our own variables for our own use.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$overlay-05: rgba(0, 0, 0, 0.05);
$overlay-10: rgba(0, 0, 0, 0.10);
$overlay-15: rgba(0, 0, 0, 0.15);
$overlay-25: rgba(0, 0, 0, 0.25);
$overlay-50: rgba(0, 0, 0, 0.50);
$overlay-90: rgba(0, 0, 0, 0.90);
$screen-15: rgba(255, 255, 255, 0.15);

/* colors */
$white:    #fff;
$gray-50: #EEF0F5;
$gray-100: #DFE3EB;
$gray-200: #C5CBD6;
$gray-300: #A8B0BD;
$gray-400: #8B94A3;
$gray-500: #717C8C;
$gray-600: #576373;
$gray-700: #3E4A5C;
$gray-800: #273345;
$gray-900: #161E2B;
$black:    #000;

$grays: ();
$grays: map-merge(
(
    "50": $gray-50,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
),
$grays
);

$green-100: #DCF2C2;
$green-200: #C2E59A;
$green-300: #A9D772;
$green-400: #8FC949;
$green-500: #76BC21;
$green-600: #68A61C;
$green-700: #5A9117;
$green-800: #4C7C12;
$green-900: #3E660D;

$greens: ();
$greens: map-merge(
        (
            "100": $green-100,
            "200": $green-200,
            "300": $green-300,
            "400": $green-400,
            "500": $green-500,
            "600": $green-600,
            "700": $green-700,
            "800": $green-800,
            "900": $green-900
        ),
        $greens
);

$cyan-100: #CFF4FC;
$cyan-200: #9EEAF9;
$cyan-300: #6EDFF6;
$cyan-400: #3DD5F3;
$cyan-500: #0DCAF0;
$cyan-600: #0BAECF;
$cyan-700: #0992AD;
$cyan-800: #08768C;
$cyan-900: #065E70;

$cyans: ();
$cyans: map-merge(
        (
            "100": $cyan-100,
            "200": $cyan-200,
            "300": $cyan-300,
            "400": $cyan-400,
            "500": $cyan-500,
            "600": $cyan-600,
            "700": $cyan-700,
            "800": $cyan-800,
            "900": $cyan-900
        ),
        $cyans
);

$yellow-100: #FFF3CD;
$yellow-200: #FFE69C;
$yellow-300: #FFDA6A;
$yellow-400: #FFCD39;
$yellow-500: #FFC107;
$yellow-600: #E3AB07;
$yellow-700: #BF9105;
$yellow-800: #997304;
$yellow-900: #735802;

$yellows: ();
$yellows: map-merge(
        (
            "100": $yellow-100,
            "200": $yellow-200,
            "300": $yellow-300,
            "400": $yellow-400,
            "500": $yellow-500,
            "600": $yellow-600,
            "700": $yellow-700,
            "800": $yellow-800,
            "900": $yellow-900
        ),
        $yellows
);

$orange-100: #FFE5D0;
$orange-200: #FECBA1;
$orange-300: #FEB272;
$orange-400: #FD9843;
$orange-500: #FD7E14;
$orange-600: #D96C11;
$orange-700: #B2590E;
$orange-800: #8C460B;
$orange-900: #663308;

$oranges: ();
$oranges: map-merge(
        (
            "100": $orange-100,
            "200": $orange-200,
            "300": $orange-300,
            "400": $orange-400,
            "500": $orange-500,
            "600": $orange-600,
            "700": $orange-700,
            "800": $orange-800,
            "900": $orange-900
        ),
        $oranges
);

$red-100: #F8D7DA;
$red-200: #F1AEB5;
$red-300: #EA868F;
$red-400: #E35D6A;
$red-500: #DC3545;
$red-600: #BD2D3B;
$red-700: #9E2632;
$red-800: #801F28;
$red-900: #61171E;

$reds: ();
$reds: map-merge(
        (
            "100": $red-100,
            "200": $red-200,
            "300": $red-300,
            "400": $red-400,
            "500": $red-500,
            "600": $red-600,
            "700": $red-700,
            "800": $red-800,
            "900": $red-900
        ),
        $reds
);

$emerald-100: #BCF7CA;
$emerald-200: #90E8A4;
$emerald-300: #5FD47A;
$emerald-400: #40BD5D;
$emerald-500: #28A745;
$emerald-600: #259940;
$emerald-700: #218A39;
$emerald-800: #1D7832;
$emerald-900: #18662B;

$emeralds: ();
$emeralds: map-merge(
        (
            "100": $emerald-100,
            "200": $emerald-200,
            "300": $emerald-300,
            "400": $emerald-400,
            "500": $emerald-500,
            "600": $emerald-600,
            "700": $emerald-700,
            "800": $emerald-800,
            "900": $emerald-900
        ),
        $emeralds
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     $red-500;
$orange:  $orange-500;
$yellow:  $yellow-500 ;
$green:   $green-500;
$teal:    #20c997 !default;
$cyan:    $cyan-500;
$emerald: $emerald-500;

$colors: () !default;
$colors: map-merge(
        (
            "blue":       $blue,
            "indigo":     $indigo,
            "purple":     $purple,
            "pink":       $pink,
            "red":        $red,
            "orange":     $orange,
            "yellow":     $yellow,
            "green":      $green,
            "teal":       $teal,
            "cyan":       $cyan,
            "emerald":    $emerald,
            "white":      $white,
            "gray":       $gray-600,
            "gray-dark":  $gray-800
        ),
        $colors
);

$primary:       $green-500;
$secondary:     $gray-500;
$success:       $green-500;
$info:          $cyan;
$warning:       $yellow;
$alert:         $orange;
$danger:        $red;
$light:         $gray-50;
$dark:          $gray-900;

$theme-colors: () !default;
$theme-colors: map-merge(
        (
            "primary":    $primary,
            "secondary":  $secondary,
            "success":    $success,
            "info":       $info,
            "warning":    $warning,
            "alert":      $alert,
            "danger":     $danger,
            "light":      $light,
            "dark":       $dark
        ),
        $theme-colors
);

$gray-light:  $gray-50;
$gray-medium: $gray-200;
$gray-dark:   $gray-900;

$tertiary: $gray-500;
$tertiary-darker: $gray-700;

$eggplant-lightest-faded: $gray-50;
$eggplant-lightest:       $gray-100;
$eggplant-light:          $gray-300;
$eggplant-mid:            $gray-600;
$eggplant:                $gray-700;
$eggplant-darkest:        $gray-900;

$darkred:                 $red-700;

$logo-green:              $green;
$logo-orange:             $orange;
$delete:                  $danger;

/* spacers */
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
        (
            0: 0,
            1: ($spacer * .25),
            2: ($spacer * .5),
            3: $spacer,
            4: ($spacer * 1.5),
            5: ($spacer * 2),
            6: ($spacer * 3),
            7: ($spacer * 4)
        ),
        $spacers
);
$space-size-ratio:  1rem;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  185;

/* Links */
// Style anchor elements.
$link-color: $primary;

/* Grid breakpoints */
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1280px,
    xl: 1600px
) !default;

// The dimension at which the menu will switch from overlay mode
// to side by side mode. This should be inline with MENU_WIDTH_OVERLAY
// in dct/src/config/index.ts
$menu-breakpoint: 1280px; // 1280 is RT10 tablet this way it always uses collapse.

/* Options */
// Quickly modify global styling by enabling or disabling optional features.
$enable-shadows: true;

// Row colour, when selected (tr.selected)
$table-row-selected: $gray-light;

// Text colour of the interaction control elements (info, filter, paging etc)
$table-control-color: $gray-dark;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-50 !default;
$body-color:                $gray-900 !default;

/* Layout */
$padding-main               : 2rem;
$padding-main-compressed    : $padding-main / 2; // 16px

$margin-main                : $padding-main;
$margin-main-compressed     : $padding-main-compressed;

$element-border-radius : 4px;
$layout-border-radius : 4px;

/* Components */
// Define common padding and border radius sizes and more.
$line-height-xs:              1.5;

$border-radius:               $layout-border-radius;
$border-radius-lg:            $border-radius;
$border-radius-sm:            $border-radius;
$border-radius-xs:            $border-radius;

$component-active-bg:         $primary;
$border-color:                $gray-200;

/* Typography */
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      'Lato', 'Open Sans', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-code:            Oswald, Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", sans-serif; // NEW

$font-size-base:              1rem;
$font-size-xs:                $font-size-base * .75;
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;
$small-font-size:             $font-size-sm;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          900;

$font-color:                  $gray-900;

$h1-font-size:                $font-size-base * 2.125;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1;
$h6-font-size:                $font-size-base * 0.875;

$headings-font-weight:        $font-weight-bold;
$text-muted:                  $gray-500;

$table-hover-bg:              $gray-50;
$table-border-color:          $white !default;
$table-head-bg:               $gray-100 !default;
$table-head-color:            $gray-900 !default;
$table-dark-color:            $white;
$table-dark-bg:               $gray-500;
$table-dark-border-color:     $white;

$control-field-padding:       .5rem;

$input-btn-padding-x:         1rem;
$input-btn-padding-y:         0.375rem;
$input-btn-padding-y-xs:      $control-field-padding;
$input-btn-padding-x-xs:      .4rem;
$input-btn-font-size-xs:      $font-size-xs;
$input-btn-line-height-xs:    $line-height-xs;
$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;

$input-btn-focus-width:       1px;
$input-btn-focus-color:       $component-active-bg !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-group-addon-bg:        $gray-100 !default;

/* Buttons */
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-padding-y-xs:            $input-btn-padding-y-xs;
$btn-padding-x-xs:            $input-btn-padding-x-xs;
$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $element-border-radius;
$btn-border-radius-lg:        $btn-border-radius;
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-xs:        $btn-border-radius;

$btn-font-size-xs:            $input-btn-font-size-xs;
$btn-line-height-xs:          $input-btn-line-height-xs;
$btn-box-shadow:              unset; // was 1px 1px 2px 0 #ccc;
$btn-focus-width:             0;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;
$btn-circle-dim:              50px;
$box-shadow:                  0 2px 3px 0px $overlay-10, 0 0 1px 1px $overlay-05;

/* Forms */
$input-border-color:  $gray-medium;
$input-border-radius: $element-border-radius;
$input-box-shadow:    unset;
$input-padding:       $control-field-padding;
$input-padding-y:     $input-btn-padding-y !default;
$input-padding-x:     $input-btn-padding-x !default;
$input-disabled-bg:         $gray-100 !default;

$field-bottom-margin:       2rem;
$custom-select-bg-size:     12px 10px;
$custom-select-indicator:   url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 532.8 331.2' style='enable-background:new 0 0 532.8 331.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23888888;stroke-width:90;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='35.5,33.9 267,265.4 497.3,35.1 '/%3E%3C/svg%3E");
$custom-select-box-shadow:  unset;

/* Alerts */
$alert-border-level:                -3 !default; // NEW
$alert-color-level:                 5 !default; // NEW


/* Pagination */
$pagination-border-color:           $gray-300 !default;
$pagination-hover-bg:               $gray-100 !default;
$pagination-hover-border-color:     $gray-300 !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;
$pagination-disabled-color:         $gray-300; // NEW - was $gray-600
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gray-300 !default;
$pagination-border-radius-sm:       $border-radius-sm !default;
$pagination-border-radius-lg:       $border-radius-lg !default;

/* Badges */
$badge-font-size:                    $font-size-xs; // NEW
$badge-font-weight:                  $font-weight-normal; // NEW
$badge-padding-y:                   .375rem;
$badge-padding-x:                   .375rem;
$badge-pill-padding-x:              .75rem; // NEW

/* Modals */
$modal-content-padding:               15px;
$modal-content-border-radius:         $border-radius !default;
$modal-header-border-width:         0 !default;
$modal-header-padding-y:            1rem;
$modal-header-padding-x:            1rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default;
$modal-footer-border-width:         0 !default;
$modal-transition:                  transform 100ms ease-out !default;

$modal-xl:                          1140px !default;
$modal-lg:                          800px !default;
$modal-md:                          600px !default; // NEW - was 500px
$modal-sm:                          300px !default;

// Toasts

$toast-min-width:                   150px;
$toast-max-width:                   350px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .25rem !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       null !default;
$toast-background-color:            $white !default;
$toast-border-width:                1px !default;
$toast-border-color:                rgba(0, 0, 0, .1) !default;
$toast-border-radius:               .25rem !default;
$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) !default;

$toast-header-color:                $gray-600 !default;
$toast-header-background-color:     rgba($white, .85) !default;
$toast-header-border-color:         rgba(0, 0, 0, .05) !default;

/* Placeholders */
$placeholder-opacity-max:           .5 !default;
$placeholder-opacity-min:           .2 !default;

/* Font Awesome */
$fa-font-path: "../../../public/webfonts";

// SideBar
$sidebar-width: 275px;

// Footer
$fixed-footer-height:   54px;

// Breadcrumbs
$breadcrumb-font-size:              $font-size-sm;
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-bg:                     unset;
$breadcrumb-divider-color:          $gray-300 !default;
$breadcrumb-active-color:           $gray-500 !default;
$breadcrumb-divider:                quote(">") !default;

// Navs
$nav-link-disabled-color:           $gray-300 !default;
$nav-tabs-border-color:             $gray-200 !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $white !default;
$nav-tabs-link-color:               $gray-600 !default;
$nav-tabs-link-active-color:        $gray-900 !default;
$nav-tabs-link-active-bg:           $white !default;
$nav-tabs-link-active-border-color: $gray-200 $gray-200 $nav-tabs-link-active-bg !default;

// Navbar

$navbar-nav-link-padding-x:         0;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-menu-mobile-overlay:        990; // Must be below $zindex-sidebar-menu
$zindex-sticky:                     1000; // Set so it's below dropdowns as by default it isn't
$zindex-sidebar-menu:               1005;
$zindex-dropdown:                   1020;
$zindex-fixed:                      1030;
$zindex-modal-backdrop:             1040;
$zindex-modal:                      1050;
$zindex-popover:                    1060;
$zindex-tooltip:                    1070;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                15rem !default;
