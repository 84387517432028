.toast {
    .toast-header {
        text-transform: uppercase;
    }

    .close {
        opacity: 1;
    }
}

.Toastify__toast {
    padding: 0 !important;

    .Toastify__toast-body {
        padding: 0 !important;
    }

    &.Toastify__toast--default,
    &.Toastify__toast--error,
    &.Toastify__toast--warning,
    &.Toastify__toast--success,
    &.Toastify__toast--info {
        background: $white !important;
        overflow: hidden;

        .toast-body {
            color: $eggplant-darkest !important;
        }
    }

    &.Toastify__toast--default,
    &.Toastify__toast--error,
    &.Toastify__toast--warning,
    &.Toastify__toast--success,
    &.Toastify__toast--info {
        .toast-header,
        .toast-close {
            color: $white !important;
        }
    }

    &.Toastify__toast--default {
        .toast-header,
        .toast-close {
            background: $info;
        }
    }

    &.Toastify__toast--error {
        .toast-header {
            background-color: $danger;

            .error {
                display: block;
            }
        }
    }

    &.Toastify__toast--warning {
        .toast-header {
            background-color: $warning;

            .warning {
                display: block;
            }
        }
    }

    &.Toastify__toast--info {
        .toast-header {
            background-color: $blue;

            .info {
                display: block;
            }
        }
    }

    &.Toastify__toast--success {
        .toast-header {
            background-color: $success;

            .success {
                display: block;
            }
        }
    }
}
