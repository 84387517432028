[class*="control"] > div:first-of-type {
    padding-left: $input-padding-x ! important;
    padding-right: $input-padding-x ! important;
    padding-top: $input-padding-y ! important;
    padding-bottom: $input-padding-y ! important;
    font-size: $font-size-base ! important;
    > div:last-of-type {
        padding-top: 0 ! important;
        padding-bottom: 0 ! important;
        margin-top: 0 ! important;
        margin-bottom: 0 ! important;
    }
}
[class*="control"] {
    border-radius: $input-border-radius; // NEW - Removed !important, was overriding specific styles
}

.form-group {
    //margin-bottom: 0;
    textarea {
        &.form-control {
            max-height: none;
            height: inherit !important;
        }
    }
    &.required {
        .form-label {
            &:after {
                content: "*";
                color: $red;
                font-size: 0.65em;
                margin-left: 3px;
            }
        }
    }
    label, .label {
        @include standard-label;
    }
    .form-control, .custom-select {
        padding-left: $input-padding-x;
        padding-right: $input-padding-x;
        padding-top: $input-padding-y ! important;
        padding-bottom: $input-padding-y ! important;
        height: unset;
        border-radius: $input-border-radius;
    }
    &.inline {
        label {
            margin-bottom: 0;
            margin-right: 5px;
        }
        &.reduced {
            [class*="container"] [class*="control"] {
                min-height: unset ! important;
            }
            .form-control, .custom-select, [class*="ValueContainer"] {
                font-size: 0.8rem ! important;
            }
            .form-control, [class*="ValueContainer"] {
                padding: 0.375rem 0.75rem ! important;
            }
            .custom-select {
                padding: 0.375rem 1.75rem 0.375rem 0.75rem;
            }
            [class*="indicatorContainer"] {
                padding: 0.2rem ! important;
            }
        }
    }
}

.custom-select {
    &:focus {
        border-color: $primary;
        // box-shadow: unset; // TODO - Not sure what this is doing, remove it?
    }
}

// TODO - fix rounded corners on interior elements of .iput-groups (append/prepend)
// Something like the following may work
// .input-group {
//     [class$="-control"] { // e.g. .css-72to3b-control
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//     }
//     .input-group-prepend {
//         & + [class$="-container"] { // e.g. .css-124uti3-container
//             [class$="-control"] { // e.g. .css-72to3b-control
//                 border-top-right-radius: $border-radius;
//                 border-bottom-right-radius: $border-radius;
//                 border-top-left-radius: 0;
//                 border-bottom-left-radius: 0;
//             }
//         }
//     }
// }

.input-static {

  .help-block {
    position: absolute;
    top: 65px;
  }
}

.group-inline {
  align-items: center;
  .btn {
    bottom: 0;
  }
}

label {
  font-weight: $font-weight-normal;
  font-size: $h6-font-size;
}
label.standard {
    @include standard-label;
}

// Re add in some margin to our checkboxes, radio buttons and toggles.
.checkbox, .checkbox-inline, .radio, .radio-inline {
  input {
    margin-right: .25rem;
  }

  label .toggle, .toggle {
    // We need to explicitly remove margin left from the styles provided in the vendor files
    margin-left: 0;
    margin-right: .25rem;
  }
}

.checkbox-inline + .checkbox-inline, .radio-inline + .radio-inline {
  input {
    margin-left: 0.5rem;
  }
  label .toggle, .toggle {
    margin-left: 0.5rem;
  }
}

.required .form-label:after {
  color: theme-color("danger");
  content: ' *';
  display: inline;
  font-weight: 600;
  font-size: 12px;
}

.row-stack > .form-column > p, .row-stack > .form-column > i {
  display: inline-block;
}

.row-stack > .form-column > .btn-submit {
  margin-right: 15px;
}

.row-stack > .col {
  padding-left: 0 !important;
}

.fa-long-arrow-right {
  color: theme-color("info");
  font-size: 25px;
  vertical-align: middle;
}

textarea.ta-full {
  width: 100%;
  border: 1px solid gray("gray-medium");
}

.error-input {
  border: 2px solid #f2b8d3;
  box-shadow: 0 0 10px #db678b;
}

.hide-number-spinner::-webkit-inner-spin-button,
.hide-number-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-number-spinner {
  -moz-appearance: textfield; /* Firefox */
}

.has-error .help-block{
  color: theme-color('danger');
}

.input-group-append {
    button:nth-of-type(2) {
        border-left: 2px solid $overlay-10;
    }
}

.image-drop-container {
    p {
        margin-bottom: 0;
        text-align: center;
    }
}

.custom-date-range-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .form-group {
        flex: 1 1;
        margin-bottom: 0;
    }
    &--icon {
        padding: 0 24px;
        font-size: 2em;
        svg {
            transform: rotate(90deg);
        }
    }
}

.form-control-plaintext {
    padding-left: 0 !important;
    &:focus { // removes focus from text (read-only) inputs
        border-color: transparent;
        outline: none !important;
        box-shadow: none !important;
    }
}
.form-control {
    &[readonly] {
        background-color: transparent;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }
}
