// jQuery UI stylesheet imports this one - there are just two places where we
// don't want DataTabels default styles assigned for jQuery UI, so rather than
// duplicating the whole file, this is the best option
$jqueryui: false !default;

.table-responsive {
    .table {
        min-width: map-get($grid-breakpoints, "lg");
    }
}

table.table {
    margin-bottom: 0;
    font-size: $font-size-xs; // NEW - will need to be scaled up to $font-size-sm
    caption {
        caption-side: top;
    }
    thead {
        th {
            border-right: solid 1px;
            border-left: solid 1px;
            border-bottom: 0;
            border-top: 0;
            &:first-of-type {
                border-left: 0;
            }
            &:last-of-type {
                border-right: 0;
            }
        }
    }

    tbody, tfoot {
        tr {
            border-bottom: 1px solid $gray-100;
        }
        td {
            vertical-align: middle !important;
            border-top: unset;
            .form-group {
                margin-bottom: 0;
            }
        }
        tr.revealable {
            background-color: $eggplant-lightest-faded;
            td {
                vertical-align: top ! important;
            }
        }
        .tc-icon {
            font-size: 25px;
            text-align: center;

            i {
                border: 1px solid;
                padding: 5px 8px;
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
            }

            .no-border {
                border: none;
            }

            .no-pointer {
                cursor: none;
            }
        }
    }
}

.table {
    &--end {
        padding: 1.5rem 0 0 0;
        label {
            @include standard-label;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}

.table-actions {
    width: 50px;
    min-width: 50px;
    &--actions {
        padding: 0 ! important;
        border-right: 1px solid $eggplant-lightest;
        .dropdown {
            position: static;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            .dropdown-toggle, .single-action {
                height: 100%;
                border-radius: 0;
                border-bottom: 0;
                min-width: 100%;
            }
            .dropdown-menu {
                padding: 0;
                overflow: hidden;
            }
            .dropdown-item {
                padding: 0.5rem 1.0rem;
                border-bottom: 1px solid $eggplant-lightest;
                &:active {
                    background-color: $eggplant;
                }
                &.disabled {
                    background-color: $gray-50;
                }
            }
            .single-action {
                position: static;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                appearance: none;
                border: none;
                background-color: unset;
            }
        }
    }
    &--buttons {
        list-style: none;
        display: flex;
        margin-bottom: 0;
        padding: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        li {
            display: flex;
        }
        button {
            min-width: 50px;
            appearance: none;
            border: unset;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.dataTables_info + .dataTables_paginate {
    float: right;
}

/* INFO TABLE ONLY */
table.infoTable, table.table table.infoTable {

    thead {
        th {
            background-color: theme-color("warning") !important;
            color: theme-color("light");
        }
    }

    tbody {
        background-color: theme-color("light");
    }

}

/* DATA TABLE ONLY */
.dataTables_wrapper {

    div.dataTables_processing {
        z-index: 105 !important;
    }

    margin-bottom: 2%;

    table.dataTable {

        //Header and footer styles
        thead {

            /* This fix is for the sorting icons, which do not behave the same with bootstrap for css and js */
            .sorting, .sorting_asc, .sorting_desc {
                &::before {
                    content: none;
                }

                &::after {
                    color: theme-color("warning");
                    font-family: 'Glyphicons Halflings', sans-serif;
                    float: right;
                    opacity: 1;
                }
            }

            .sorting::after {
                content: "\e150" !important;
            }

            .sorting_asc::after {
                content: "\e155" !important;
            }

            .sorting_desc::after {
                content: "\e156" !important;
            }


        }
    }
}

/* Pagination */
.pagination-container {
    flex: 1;
    display: flex;
    justify-content: center;
    .pagination {
        margin: 0;
        .page-item {
            font-weight: $font-weight-bold;
            [aria-hidden="true"] {
                font-size:1.5rem;
                line-height: 0.25;
            }
        }
        i {
            font-size: 0.75rem;
        }
    }
}

//.nested-section {
//    table.table {
//        @include nested-border;
//    }
//    .table th, .table td {
//        border-top: 0;
//    }
//}

.row-attention {
    border: 5px solid $red;
    &--top {
        border: 5px solid $red;
        border-bottom: unset;
    }
    &--bottom {
        border: 5px solid $red ! important;
        border-top: unset ! important;
    }
}
