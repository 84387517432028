.tour-plan-map {
    height: calc(100vh - 250px);
}

.view-tour-map {
    height: calc(100vh - 235px);
}

.generate-sequence-map {
    height: calc(100vh - 280px);
}
