.form-inline .multiselect-container label.checkbox, .form-inline .multiselect-container label.radio {
  padding: 3px 20px 3px 40px
}

.form-inline .multiselect-container li a label.checkbox input[type=checkbox], .form-inline .multiselect-container li a label.radio input[type=radio] {
  margin-left: -20px;
  margin-right: 0
}

.bootstrap-multiselect {

  font-family: inherit;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  color: $gray-dark;

  span.multiselect-native-select {

    position: relative;

    select {
      border: 0 !important;
      clip: rect(0 0 0 0) !important;
      height: 1px !important;
      margin: -1px -1px -1px -3px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
      left: 50%;
      top: 30px
    }

    .btn-group {
      width: 100%;

      .btn-group:nth-child(2) > .multiselect.btn {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px
      }

      .dropdown-toggle {
        margin-top: 0;
        width: 100%;
        border: 1px solid $gray-medium;
        border-radius: 0%;
        box-shadow: none;

        span.multiselect-selected-text {
          float: left;
          margin-left: 0 !important;
        }
      }

      .multiselect-container {
        width: 100%;
        position: absolute;
        list-style-type: none;
        margin: 0;
        padding: 0;

        .multiselect-reset .input-group {
          width: 93%
        }

        .input-group {
          margin: 5px
        }

        li.multiselect-group label {
          margin: 0;
          padding: 3px 20px;
          height: 100%;
        }

        .multiselect-container > li.multiselect-group-clickable label {
          cursor: pointer
        }

        li {
          padding: 0;

          a {
            padding: 0;

            &:focus {
              outline: none;
            }

            label.checkbox {
              margin: 0;
              height: 100%;
              cursor: pointer;
              padding: 1% 2% 1% 2%;
              width: 100%;
              color: $gray-dark;

              &:focus {
                outline: none;
              }

              input[type=checkbox] {
                margin-bottom: 1%;
                margin-right: 5%;
              }
            }
          }

          &:hover {
            background-color: theme-color("light");
          }
        }
      }
    }
  }
}