.btn {
  // Add our margins and helper styling for any icon that's added to a button.
    line-height: 1.5rem;
    padding: $btn-padding-y $btn-padding-x;

    // NEW - styles to handle buttons with icons left or right
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem; /* Sets the spacing between child grid items */

    &:not(a) {
        box-shadow: unset;
    }
    span {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0;
        padding: 0;
    }
    &-primary {

    }
    /* Equals btn-outline-primary */
    &-secondary {
        background-color: transparent;
        color: $green;
        border: 1px solid $green;
        &:hover {
            background-color: $green;
            border: 1px solid $green;
            color: $white;
        }
    }
    /* Equals btn-secondary */
    &-tertiary {
        background-color: $tertiary;
        color: $white;
        &:hover {
            background-color: $tertiary-darker;
            color: $white;
        }
    }
    &-field {
        background-color: $gray-300;
    }
    &-text {
        box-shadow: unset ! important;
        border: 0;
        &:hover {
            text-decoration: underline;
            color: $gray-700;
        }
    }
    &-action {
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        min-width: unset;
        border: 1px solid $gray-500;
        box-shadow: none ! important;
        color: $gray-700;
        &.btn-secondary, &.btn-primary, &.btn-tertiary {
            padding: 0;
        }
    }
    i, .fa, .fas, .far, .fal, .fad, .fab {
        line-height: $line-height-base; // NEW - sets icon-only buttons to same height as other buttons
    }
    &-icon-only {
        i, .fa, .fas, .far, .fal, .fad, .fab, .svg-inline--fa {
            padding: 0 !important; // NEW
            line-height: $line-height-base; // NEW - sets icon-only buttons to same height as other buttons
        }
    }
    &-dropdown {
        box-shadow: unset ! important;
    }
    &-toggle {
        $toggle-height: 30px;
        width: 65px;
        height: $toggle-height;
        border-radius: 50px;
        box-shadow: inset 0 0 2px 2px $overlay-05;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 7px;
        border: unset;
        background-color: $gray-300;
        font-size: 12px;
        &.on {
            background-color: $primary;
            .indicator {
                text-align: left;
            }
            .dial {
                right: 0;
            }
        }
        &.off {
            .indicator {
                text-align: right;
                color: $overlay-50;
            }
            .dial {
                left: 0;
            }
        }
        &:focus {
            outline: 0;
        }
        .indicator {
            text-transform: uppercase;
            display: block;
            width: 100%;
        }
        .dial {
            width: $toggle-height;
            height: $toggle-height;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: 0 0 2px 1px $overlay-15;
            background-color: $gray-700;
        }
    }
}

.dropdown-combined {
    border-radius: $btn-border-radius;
    box-shadow: $btn-box-shadow;
    overflow: hidden;
    button {
        margin: 0 ! important;
        border-radius: 0 ! important;
        box-shadow: none;
    }
    .dropdown-toggle {
        border-left: 1px solid $overlay-25;
        min-width: unset;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@each $color, $value in $theme-colors {
  .btn-link-#{$color} {
    @include button-link-variant($value);
  }
}

// We add an additional size option.
.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

// TODO below classes need to be switch over to new styles in there implementation. Once implementations changed we can
//  remove the code below

.btn-submit {
  background-color: theme-color('success');
  color: white;

  &:hover {
    background-color: color-yiq(darken(theme-color("success"), 7.5%));
  }
}

.btn-reset {

  @include button-variant(theme-color("warning"), theme-color("warning"));

  background-color: color('white');
  color: theme-color("warning");
  border-color: color('white');

  text-decoration: underline;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &:hover {
    background-color: color('white');
    color: theme-color("warning");
    border-color: color('white');
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle, &:focus, &:active {
    -webkit-box-shadow: none; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none; /* Firefox 3.5 - 3.6 */
    box-shadow: none;
    background-color: color('white');
    color: theme-color("warning");
    border-color: color('white');
    text-decoration: none;
  }
}

.btn-bottom {
  position: absolute;
  bottom: 0;
}

.btn-delete {
    background-color: $delete;
    color: white;
    &:hover {
        background-color: $darkred;
        color: white;
    }
}

.input-group-prepend .btn, .input-group-append .btn {
    min-width: unset;
}

.btn-circle-icon {
    border-radius: 50%;
    width: $btn-circle-dim;
    height: $btn-circle-dim;
    border: none;
    border-bottom: 3px solid $overlay-15;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    -webkit-appearance: none;
}

button, [type=button], [type=reset], [type=submit] {
    appearance: unset;
}

// Used for Button Toolbar where multiple buttons are in a row.
.button-row {
    &.left {
        display: flex;
    }
    &.right {
        display: flex;
        margin-left: auto !important;
    }
    &> *:not(:last-child) {
        margin-right: 0.5rem;
    }
}

// Ensure multiple Button Groups within a Button Toolbar are spaced
// out without putting gaps in the Button Group itself.
.btn-toolbar .btn-group:not(:first-child) {
    margin-left: 1rem;
}

// NEW - Button Groups
.btn-group {
  .btn:not(:first-child) {
    margin-left: 1px !important;
  }
}

// NEW - need to add these here because the previous styles override the btn-sm styles.
.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

