ul.tagging_ul {

  @extend .form-control;
  padding-bottom: 0.1rem;
  padding-top: 0.1rem;
  @include form-control-focus();

}

ul.tagging_ul li {

  height: inherit;
  margin-right: 5px;
  white-space: nowrap;
}

ul.tagging_ul li.tagging_tag {
  background-color: #555;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  height: inherit;
  padding: 0.1rem 0.3rem;
  font-size: $font-size-base;
  height: 85%;
  position: relative;
  top: 0.1rem;
  z-index: 1;
}


ul.tagging_ul li.tagging_tag span.tag_delete {
  color: #555;
  margin-left: 0.2rem;
  cursor: pointer;
}

ul.tagging_ul li.tagging_tag span.tag_delete:hover {
  color: #fff;
}

ul.tagging_ul li.tagging_new {
  display: inline-block;
  height: inherit;
  margin-left: 0;
  max-width: 200px;
  position: absolute;
  bottom: 1rem;
}

ul.tagging_ul li.tagging_new input.tagging_new_input {
  border: none;
  outline: none;
  box-shadow: none;
  display: inline;
  height: inherit;
  padding-bottom: 0;
  padding-top: 0;
  max-width: inherit;
  color: black;
}

.tt-dropdown-menu {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #555;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #555;
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.tt-dropdown-menu .tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}

.tt-dropdown-menu .tt-suggestion p {
  margin: 0;
}

.tt-dropdown-menu .tt-cursor {
  color: #fff;
  background-color: #555;
}

.tagging_new span {
  color: black;
}


.tt-input {
  color: black;
  vertical-align: middle !important;
  top: 10px !important;
  width: 100% !important;
}

.tt-hint {
  top: 50% !important;
  width: 100% !important;
}

.btn-tag-add {
  color: #9bbe45;
  font-size: 25px;
}

.btn-tag-close {
  color: #d9534f;
  font-size: 25px;
}
