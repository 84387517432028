.rc-tree {
    margin: 0;
    border: 1px solid transparent;
    font-size: 0.875rem;
    display: block; // responsive scrolling
    width: 100%; // responsive scrolling
    overflow-x: auto; // responsive scrolling
    .rc-tree-list {
        min-width: min-content; // responsive scrolling. This 'should' be compatible with most browsers,
        // but if we have issues it may need to be changed to a value (e.g. 600px)
    }
    .rc-tree-treenode {
        margin: 0;
        padding: 0;
        line-height: 2rem;
        white-space: nowrap;
        list-style: none;
        outline: 0;
        display: flex;
        &:hover {
            background-color: $gray-100;
            .rc-tree-action-icons {
                visibility: visible !important;
            }
        }
        &.rc-tree-treenode-selected {
            background-color: $green-100;
        }
        .draggable {
            color: #333;
            -moz-user-select: none;
            //-khtml-user-select: none;
            -webkit-user-select: none;
            user-select: none;
        }
        &.dragging {
            background: rgba(100,100,255,0.1);
        }
        &.drop-container {
            & > .draggable {
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    box-shadow: inset 0 0 0 2px red;
                    content: '';
                }
            }
            & ~ .rc-tree-treenode {
                border-left: 2px solid chocolate;
            }
        }
        &.drop-target {
            background-color: yellowgreen;
            & ~ .rc-tree-treenode {
                border-left: none;
            }
        }
        &.filter-node {
            & > .rc-tree-node-content-wrapper {
                color: #a60000 !important;
                font-weight: bold !important;
            }
        }
        ul {
            margin: 0;
            padding: 0 0 0 1rem;
        }
        .rc-tree-node-content-wrapper {
            display:  flex;
            flex:  1 1 auto;
            align-items: center;
            margin: 0;
            padding: 0;
            text-decoration: none;
        }

        .rc-tree-switcher,
        .rc-tree-checkbox,
        .rc-tree-iconEle {
            display:  inline-flex;
            outline: none;
        }
        .rc-tree-iconEle > span {
            display:  inline-flex;
            width:  2rem;
            height:  2rem;
            border-radius:  $element-border-radius;
            align-items: center;
            justify-content: center;
        }
        .rc-tree-iconEle {
            .root {
                background-color: $orange-500;
            }
            .branch {
                background-color: $cyan-500;
            }
            .leaf {
                background-color: $green-500;
            }
        }

        .rc-tree-switcher,
        .rc-tree-checkbox {
            display:  inline-flex;
            width:  3rem;
            min-width:  3rem;
            height:  3rem;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: $gray-500;
            &:hover {
                color: $gray-900;
            }
            &.rc-tree-switcher-noop {
                cursor: auto;
            }
        }

        span.rc-tree-switcher.rc-tree-icon__customize,
        span.rc-tree-checkbox.rc-tree-icon__customize,
        span.rc-tree-iconEle.rc-tree-icon__customize {
            background-image: none;
        }
        .rc-tree-indent {
            display: inline-block;
            height: 0;
            vertical-align: bottom;
        }

        .rc-tree-indent-unit {
            display: inline-block;
            width: 2rem;
        }

        /*.rc-tree-iconEle.root {
            background-color: $orange-500;
        }
        .rc-tree-iconEle.branch {
            background-color: $cyan-500;
        }
        .rc-tree-iconEle.leaf {
            background-color: $green-500;
        }*/
    }
}

.rc-tree-focused {
    &:not(.rc-tree-active-focused) {
        border-color: cyan;
    }
}


.rc-tree-child-tree {
    display: none;
}
.rc-tree-child-tree-open {
    display: block;
}
.rc-tree-treenode-disabled > span:not(.rc-tree-switcher),
.rc-tree-treenode-disabled > a,
.rc-tree-treenode-disabled > a span {
    color: $gray-300;
    cursor: not-allowed;
}
.rc-tree-treenode-active {
    background: rgba(0,0,0,0.1);
}

.rc-tree-icon__customize {
    margin-right: 2px;
    vertical-align: top;
}
.rc-tree-title {
    display:  flex;
    flex:  1 1 auto;
    align-items: center;
    padding-left:  1rem;
    .rc-tree-action-icons {
        display:  inline-flex;
        flex:  1 1 auto;
        justify-content: flex-end !important;
        align-items: center;
        visibility: hidden;
        font-size: $font-size-lg;
        color: $gray-500;
        .svg-inline--fa, .fa {
            display:  inline-flex;
            align-items: center;
            justify-content: center;
            font-size:  $font-size-lg;
            color: $gray-500;
            width:  3rem;
            height:  3rem;
            &:hover {
                color: $gray-900;
            }
        }
    }
}

.rc-tree-draggable-icon {
    display: inline-flex;
    justify-content: center;
    width: 16px;
}

// Disable the icon as we use our own.
// re-align based on flex display for parent rc-tree-node-content-wrapper
.rc-tree .rc-tree-treenode span.rc-tree-switcher {
    background-image: none !important;
}

// Node Icons
.node-icon {
    width: 2rem;
    height: 2rem;
    border-radius: $element-border-radius;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    &.root {
        background-color: $orange-500;
    }
    &.branch {
        background-color: $cyan-500;
    }
    &.leaf {
        background-color: $green-500;
    }
}
