@mixin button-outline-variant($color, $color-hover: darken($color, 7.5%), $active-background: $color, $active-border: darken($color, 7.5%)) {
  color: $color;
  border-color: $color;

  @include hover {
    color: $color-hover;
    //background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    //background-color: $active-background;
    border-color: $active-border;
    transform: translate(1px, 1px);

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}

@mixin button-link-variant($color, $color-hover: darken($color, 7.5%)) {
  // Make a button look and behave like a link
  font-weight: $font-weight-normal;
  color: $color;
  text-decoration: $link-decoration;

  @include hover {
    color: $color-hover;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

@mixin standard-label() {
    text-transform: uppercase; // TODO - Remove once Translation capitalization can be reviewed
    font-weight: $font-weight-normal; // TODO - change to $headings-font-weight once Uppercase is removed
    margin-bottom: 0.25rem;
    font-size: $h6-font-size;
}

@mixin header-line() {
    &:before {
        content: '';
        border-left: 2.5px solid black;
        margin-right: 6px;
    }
}
@mixin flex-one() {
    flex: 1;
}
@mixin nested-border() {
    border-left: 4px solid $eggplant;
}

// Variable spacing for margins and padding

// Margins

@mixin margin-x-main() {
    margin-left: 1rem;
    margin-right: 1rem;

    @include media-breakpoint-up(md) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@mixin margin-bottom() {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
    }
}

// Padding

@mixin padding-main() {
    padding: 1rem;

    @include media-breakpoint-up(md) {
        padding: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        padding: 2rem;
    }
}

@mixin padding-x-main() {
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up(md) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
@mixin padding-bottom() {
    padding-bottom: 1rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 2rem;
    }
}

// Add utility classes

.margin-x-main {
    @include margin-x-main;
}
.margin-bottom {
    @include margin-bottom;
}
.padding-main {
    @include padding-main;
}
.padding-x-main {
    @include padding-x-main;
}
.padding-bottom{
    @include padding-bottom;
}
