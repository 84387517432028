.vl-inventory-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $gray-200;
}

.inventory-circle-icon {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
}

.sku-product-card {
    img {
        height: 160px;
        width: 160px;
        object-fit: contain;
        margin-right: 10px;
        margin-left: 5px;
    }
}

.vt-mtb-1 {
    border-top: 1px solid $gray-100;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    left: 10%;
    right: 10%;
}

.inventory-modal-header {
    padding-bottom: 16px;
    padding-top: 16px;
    background-color: $gray-50;
}

// TODO Move this to Buttons or a non inventory specific scss file
.formik-toggle-btn-group {
    .formik-toggle-btn-primary {
        background-color: $gray-200;
        border-color: $gray-200;
        &:active {
            background-color: $gray-700 !important;
            border-color: $gray-700 !important;
        }
        &:hover {
            background-color: $gray-500 !important;
            border-color: $gray-500 !important;
        }
    }
    .active {
        background-color: $gray-700 !important;
        border-color: $gray-700 !important;
        &:hover {
            background-color: $gray-700 !important;
            border-color: $gray-700 !important;
        }
    }
}


.formik-toggle-btn-group > .formik-toggle-btn-primary:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.formik-toggle-btn-group > .formik-toggle-btn-primary:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.formik-toggle-btn-group > .formik-toggle-btn-primary:not(:first-child) {
    margin-left: -1px;
}
