

.navbar-nav {
    float: right;
}


#accordion {
    overflow-x: hidden;
}

.dropdown-toggle::after {
    display: none;
}

.flex-one {
    @include flex-one;
}

.page-heading {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0;
    &-icon {
        align-self: center;
        svg, img {
            height: 34px;
            width: auto;
            margin-right: 1rem;
            @include media-breakpoint-up(lg) {
                height: 40px;
            }
        }
    }
    h1 {
        flex: 1;
        margin-right: 1.5rem;
        margin-bottom: 0;
    }
}

.section-heading {
    display: flex;
    align-items: center;
    margin-bottom: $margin-main - 1rem * 0.625;
    &:not(:first-child) { // NEW
        margin-top: 1.5rem;
    }
    .section-heading-title {
        @extend .h3; // NEW
        flex: 1 1;
        margin-bottom: 0;
    }
}
.subsection-heading {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    &.ntm {
        margin-top: 0;
    }
    h3, h4 {
        flex: 1 1;
        margin-bottom: 0px;
    }
}

.p-main {
    padding: $padding-main;
    &-y {
        padding: {
            top: $padding-main;
            bottom: $padding-main;
            left: 0;
            right: 0;
        }
    }
    &-x {
        padding: {
            top: 0;
            bottom: 0;
            left: $padding-main;
            right: $padding-main;
        }
    }
    &-condensed {
        padding: $padding-main-compressed;
    }
}

.field-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    .form-group {
        margin-bottom: $spacer;
    }
}

.field-block {
    @include padding-main; // was padding: $padding-main;
    background-color: $white;
    margin: 1rem 0;
    border-radius: $border-radius;
    box-shadow: 0 2px 3px 0 $overlay-10;
    &.compressed {
        padding: $padding-main-compressed;
        // margin: 1rem $margin-main-compressed;
    }
    &.nested {
        background-color: unset;
        padding: 0;
        box-shadow: unset;
        margin-top: $margin-main;
        > .section-heading {
            margin-bottom: 0;
        }
    }
    &.full-width {
        $full-width-padding: 1rem * 0.25;//4px;
        padding: {
            left: $full-width-padding;
            right: $full-width-padding;
        }
        > .field-row {
            padding: 0 $padding-main - $full-width-padding;
        }
        > h* {
            padding: 0 $padding-main - $full-width-padding;
        }
        .section-heading {
            padding: 0 $padding-main - $full-width-padding;
        }
        .subsection-heading {
            padding: 0 $padding-main - $full-width-padding;
        }
        .empty-container {
            margin: 0 $margin-main - $full-width-padding;
        }
    }
    .field-block {
        box-shadow: unset;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: $padding-main - (1rem * 0.25);
        padding-bottom: $padding-main + (1rem * 0.375);
        border-bottom: 3px solid $eggplant;
        border-radius: 0;
        &.first {
            padding-top: 0;
        }
        &.last {
            padding-bottom: 0;
            border-bottom: 0;
        }
        div:first-of-type {
            margin-top: 0;
        }
        .field-block {
            border-bottom: 1px solid $eggplant;
            &.last {
                border-bottom: 0;
            }
        }
    }
    .table-wrapper {
        $offset: 1rem * 0.25;
        margin-left: -1rem + $offset;
        margin-right: -1rem + $offset;

        @include media-breakpoint-up(md) {
            margin-left: -1.5rem + $offset;
            margin-right: -1.5rem + $offset;
        }
        @include media-breakpoint-up(lg) {
            margin-left: -2rem + $offset;
            margin-right: -2rem + $offset;
        }
        position: relative;
    }
    .table--loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(70,70,70,.5);
        z-index: 10;
    }
    .table--end {}

    @media (max-width: map-get($grid-breakpoints, 'md')) {
        // padding: $padding-main-compressed $padding-main-compressed - $spacer;
        margin: $margin-main-compressed;
    }
}

// ADD
.tab-pane {
    >.field-block {
        margin-top: 0;
    }
}
// ADD End

.option-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 5px;
    margin-bottom: $spacer;
    //background-color: $eggplant-lightest;
    //border-radius: 50px;
    //box-shadow: inset 0 0 1px 1px $overlay-10;
    h5 {
        margin-bottom: 0;
        margin-right: 1rem;
    }
    &.smaller {
        .btn-toggle {
            height: 25px;
            min-width: 60px;
        }
        .dial {
            height: 25px;
            width: 25px;
        }
    }
    &.xsmaller {
        display: unset !important;
        text-align: -webkit-center;
        .btn-toggle {
            height: 24px;
            width: 55px;
        }
        .dial {
            height: 25px;
            width: 25px;
        }
    }
}

.inline-message {
    //border-left: 3px solid $eggplant;
    //padding-left: 0px;
    display: flex;
    align-items: center;
    p {
        font-style: italic;
        padding: 5px 10px;
        margin-bottom: 0;
    }
    span {
        font-size: 1rem;
        color: $eggplant;
        cursor: default;
    }
}

//.info-box {
//    display: flex;
//    align-items: center;
//    background-color: $eggplant-lightest;
//    color: $eggplant-darkest;
//    max-width: 50%;
//    box-shadow: $box-shadow;
//    &--content {
//        flex: 1;
//        padding: {
//            top: 20px;
//            bottom: 20px;
//            right: 25px;
//            left: 0px;
//        }
//        p {
//            line-height: 1.75em;
//            font-size: 1.2em;
//            margin-bottom: 0;
//        }
//    }
//    &--icon {
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        flex: 0;
//        font-size: 2em;
//        min-width: 75px
//    }
//    @media (max-width: map-get($grid-breakpoints, "lg")) {
//        max-width: 100%;
//    }
//}

//.nested-section {
//    padding: $padding-main {
//        right: 0;
//    }
//    background-color: $white;
//    &.coloured {
//        background-color: $eggplant-lightest
//    }
//    @media (max-width: map-get($grid-breakpoints, "md")) {
//        padding: $padding-main-compressed {
//            right: 0;
//        };
//    }
//}

.form-footer {
    transition: all 0.3s;
    background-color: $gray-700;
    color: $white;
    min-height: $fixed-footer-height;
    @include padding-x-main;
    padding-top: 0.5rem;
    &.fixed-bottom {
        z-index: 987;
    }
    .button-row {
        .btn {
            margin-bottom: 0.5rem;
        }
    }
    .btn-text {
        color: $gray-100; // NEW - was $eggplant-lightest
        &:hover {
            color: $white;
        }
    }
}

//.splitter {
//    flex: 1;
//}

.header-with-btn {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h1, h2, h3, h4, h5, h6 {
        flex: 1;
        margin-bottom: 0;
    }
}

.inline-row {
    display: flex;
    align-items: center;
    &.right {
        justify-content: flex-end;
        &.spaced > * {
            margin-left: 10px;
        }
    }
    &.left {
        justify-content: flex-start;
        &.spaced > * {
            margin-right: 10px;
        }
    }
}

//.contact-fields {
//    &:not(:last-of-type) {
//        margin-bottom: 35px;
//    }
//    &:not(:first-of-type) {
//        margin-top: 35px;
//    }
//    &.nested {
//        padding-left: $padding-main;
//        @include nested-border;
//    }
//}

.empty-container {
    $spacing: 20px;
    background-color: $gray-50;
    padding: ($padding-main * 2);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;
    font-size: 1.5rem;
    &--icon {
        display: flex;
        justify-content: flex-end;
        padding-right: $spacing;
        color: $eggplant;
    }
    &--details {
        padding-left: $spacing;
        font-style: italic;
        font-weight: 400;
        > div {
            margin: 10px 0;
        }
    }
    &--subtitle {
        font-size: $font-size-base;
    }
}

.detail-row {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    //padding: 0 $spacer;
    .detail-row--detail {
        flex: 1 1;
        display: flex;
        align-items: center;
        background-color: $eggplant-lightest-faded;
        padding: 0.8rem;
        margin-right: 1px;
        .label {
            flex: 1;
            @include standard-label;
            margin-bottom: 0;
        }
        .detail {
            font-weight: 800;
            font-size: 1rem;
        }
        &.hover:hover {
            background-color: $eggplant-lightest-faded;
            cursor: pointer;
        }
        &.no-label {
            .detail {
                font-weight: normal;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
    a {
        color: inherit;
    }
    &.condensed {
        .detail-row--detail {
            padding: 0.4rem;
        }
    }
}

//.left-nav-inactive {
//    border-right: 0;
//    box-shadow: 2px 0 2px 0px $overlay-25;
//}

.map-container {
    background-color: $eggplant-lightest;
    .mapboxgl-canvas-container {
        width: 100%;
        height: 100%;
        canvas {
            width: 100%;
            height: 100%;
            background-color: $eggplant-lightest;
        }
    }
}

//.latlngbar {
//    display: flex;
//    margin-top: 35px;
//    .col {
//        flex: 1;
//        padding: 0;
//        .detail-row--detail {
//            font-style: italic;
//        }
//    }
//    h5 {
//        flex: 1;
//        margin-bottom: 0;
//    }
//    &-action {
//        display: flex;
//        background-color: white;
//        border-top-right-radius: 10px;
//        border-bottom-right-radius: 10px;
//        //margin-bottom: 1px;
//        margin-left: 1px;
//        overflow: hidden;
//        button {
//            flex: 1;
//            display: flex;
//            align-items: center;
//            justify-content: center;
//            padding: 0 20px;
//            border-radius: 0;
//            span {
//                padding-left: 0;
//            }
//        }
//    }
//}

.map-options-container {
    padding: 1rem $padding-main;
    background-color: $eggplant-lightest;
    margin-top: 1px;
}

.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: $overlay-10;
    animation: loaderMove 1500ms infinite;
    @keyframes loaderMove {
        0% {
            left: 0px;
            width: 0%;
        }
        50% {
            width: 40%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }
    &.hide {
        display: none;
    }
}

.rc-steps {
    .rc-steps-item-process {
        .rc-steps-item-icon {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .rc-steps-item-finish {
        .rc-steps-item-icon {
            border-color: $primary;
            > .rc-steps-icon {
                color: $primary;
            }
        }
        .rc-steps-item-tail:after {
            background-color: $primary;
        }
    }
}

.drawer{
    z-index: 1045 !important;
}

.drawer-right .drawer-handle {
    top: 0;
    bottom: 0;
    height: unset;
}

.indicator {
    .indicator--title {
        display: flex;
        align-items: center;
        h5 {
            flex: 1 1;
            margin-bottom: 0;
        }
        .badge {
            font-size: $font-size-xs;
            > div {
                margin-right: 5px;
            }
        }
    }
}


