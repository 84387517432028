/* Basic Bootstrap table */
.flex-table {
    width: 100%;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.
    display: block;
    overflow: auto;
    font-size: $font-size-sm;

    .th,
    .td {
        padding: $table-cell-padding;
        vertical-align: top;
    }
    .td {
        border-bottom: $table-border-width solid $table-head-bg;
        .form-group >  div[class*="-container"] > div[class*="-menu"] {
            position: static;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }
        .dropdown{
            vertical-align: middle;
            height: 100%;
        }
    }

    .thead .th {
        overflow-y: auto;
        overflow-x: hidden;
        vertical-align: bottom;
        font-weight: $font-weight-bold; // NEW
        display: flex; // NEW
        align-items: center; // NEW
        justify-content: space-between; // NEW
        .fa, .fas, .svg-inline--fa {
            line-height: 1;
            font-size: $font-size-lg;
            display: inline-flex;
            width: 1.25rem;
            height: 1.25rem;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
        }
    }

    .tbody {
        a {
            color: $table-color;
        }
    }

    .tbody + .tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    .resizer {
        display: inline-block;
        background: $white;
        width: 3px;
        height: 100%;
        position: absolute;
        cursor: ew-resize;
        margin-left: -3px;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        /* prevents from scrolling while dragging on touch devices */
        touch-action: none;

        &.isResizing {
            background: red;
        }
    }
}

// Condensed table w/ half padding
.flex-table-sm {
    .th,
    .td {
        padding: $table-cell-padding-sm;
    }
}

// Border versions
// Add or remove borders all around the table and between all the columns.
.flex-table-bordered {
    border: $table-border-width solid $table-border-color;
    .th,
    .td {
        border: $table-border-width solid $table-border-color;
    }
    .thead {
        .th,
        .td {
            border-bottom-width: 2 * $table-border-width;
        }
    }
}

.flex-table-borderless {
    .th,
    .td,
    .thead .th,
    .tbody + .tbody {
        border: 0;
    }
}

// Zebra-striping
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.flex-table-striped {
    .tbody .tr:nth-of-type(#{$table-striped-order}) {
        background-color: $table-accent-bg;
    }
}

// Hover effect
// Placed here since it has to come after the potential zebra striping
.flex-table-hover {
    .tbody .tr {
        @include hover() {
            .td {
                color: $table-hover-color;
                background-color: $table-hover-bg;
            }
        }
    }
}

// Dark styles
// Same table markup, but inverted color scheme: dark background and light text.
// stylelint-disable-next-line no-duplicate-selectors
.flex-table {
    .thead-dark {
        .th {
            color: $table-dark-color;
            background-color: $table-dark-bg;
            border-color: $table-dark-border-color;
            .fa, .fas, .svg-inline--fa {
                color: $white;
            }
            &:hover {
                .fa, .fas, .svg-inline--fa {
                    color: $gray-300;
                }
            }
        }
    }
    .thead-light {
        .th {
            color: $table-head-color;
            background-color: $table-head-bg;
            border-color: $table-head-bg;
            .fa, .fas, .svg-inline--fa {
                color: $gray-500;
            }
            &:hover {
                .fa, .fas, .svg-inline--fa {
                    color: $gray-900;
                }
            }
        }
    }
}

.flex-table-dark {
    color: $table-dark-color;
    background-color: $table-dark-bg;
    .th,
    .td,
    .thead .th {
        border-color: $table-dark-border-color;
    }
    &.flex-table-bordered {
        border: 0;
    }
    &.flex-table-striped {
        .tbody .tr:nth-of-type(#{$table-striped-order}) {
            background-color: $table-dark-accent-bg;
        }
    }
    &.flex-table-hover {
        .tbody .tr {
            @include hover() {
                .td {
                    color: $table-dark-hover-color;
                    background-color: $table-dark-hover-bg;
                }
            }
        }
    }
}

// Responsive tables
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.
.flex-table-responsive {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;

                // Prevent double border on horizontal scroll due to use of `display: block;`
                > .table-bordered {
                    border: 0;
                }
            }
        }
    }
}
