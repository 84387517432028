.sidebar,
.sidebar form {
    flex-grow: 1;
    align-self: start;
    position: sticky;
    top: 1rem;

    @include media-breakpoint-up(md) {
        max-height: calc(100vh - 2rem);
        display: grid;
        grid-template-rows: auto 1fr auto;
    }
}

.sidebar {
    .section {
        margin-top: 0 !important;
        margin-bottom: 0.25rem !important;
        .section-header,
        .section-footer {
            padding-left: $padding-main-compressed;
            padding-right: $padding-main-compressed;
        }
        .section-header {
            display: block;
        }
        .section-body {
            padding: $padding-main-compressed;
        }
    }
    .sidebar-content {
        overflow-y: auto;
    }
    .sidebar-footer {
        .section {
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}
