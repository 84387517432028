// Font smoothing and display

html * {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

//
// Headings
//

h1, .h1 { @include font-size($h1-font-size); font-weight: $font-weight-normal; } // NEW
h2, .h2 { @include font-size($h2-font-size); }
h3, .h3 { @include font-size($h3-font-size); }
h4, .h4 { @include font-size($h4-font-size); font-weight: $font-weight-bolder; } // NEW
h5, .h5 { @include font-size($h5-font-size); font-weight: $font-weight-bolder; } // NEW
h6, .h6 { @include font-size($h6-font-size); font-weight: $font-weight-bolder; } // NEW


//
// Responsive Type
//

@include media-breakpoint-down(md) {
  h1, .h1 {font-size: 1.75rem;}
  h2, .h2 {font-size: 1.5rem;}
  h3, .h3 {font-size: 1.375rem;}
  h4, .h4 {font-size: 1.125rem;}
  h5, .h5 {font-size: 1rem;}
  h6, .h6 {font-size: 1rem;}
}


// Sizes

.font-sm   { font-size: $font-size-sm !important; }
.font-md   { font-size: $font-size-base !important; }
.font-lg   { font-size: $font-size-lg !important; }


// Code

.font-code {
    font-family: $font-family-code;
    font-weight: bold;
    &-sm {
        font-size:  1.25rem;
    }
    &-md {
        font-size:  1.5rem;
    }
    &-lg {
        font-size:  2rem;
    }
}

// Font Awesome

.fa-stack {
    width:  2rem !important;
}


