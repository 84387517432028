/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#wrapper {
    display: flex;
    width: 100%;
}

#main-navbar {
    background-color: #3E4A5C;
}

#nav-sidebar {
    background-color: #3E4A5C;
    width: $sidebar-width;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: $zindex-sidebar-menu;
    transition: all 0.3s;
    margin-left: -$sidebar-width;

    .navbar-brand {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
    }

    &.active {
        margin-left: 0;
    }

    .sub-menu-group {
        @include hover-focus() {
            background-color: $gray-900;
        }
        &.open {
            background-color: $gray-900;
        }

        .sub-menu-header {
            display: block;
        }
    }

    .nav-link {
        color: $gray-300;

        @include hover-focus() {
            color: $white;
        }

        &.active {
            color: $white;
            font-weight: $font-weight-bolder;

            //color: $dropdown-link-active-color;
            //text-decoration: none;
            //@include gradient-bg($dropdown-link-active-bg);
        }
    }

    // TODO Hide menu's scroll and only show scroll on hover.
    .menu-list {
        display: block;
        // Sub out the height of the top navbar and footer
        // Top navbar currently 56px
        height: calc(100% - 56px - $fixed-footer-height);

        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &:hover {
            -ms-overflow-style: auto;  /* IE and Edge */
            scrollbar-width: auto;
        }
    }

    .menu-list::-webkit-scrollbar {
        display: none;
    }
}

.navbar-brand {
    img {
        max-height: 28px;
    }
}

.user-icon, .help-icon {
    color: $component-active-bg;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#main {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

#main.active {
    width: calc(100% - $sidebar-width);

    .form-footer {
        width: calc(100% - $sidebar-width);
        margin-left: $sidebar-width;
    }
}

#content {
    @include padding-x-main;
    @include padding-bottom;
    padding-top: 1rem;
    margin-bottom: $fixed-footer-height;
}

#menu-close-button {
    display: none;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: $menu-breakpoint) {
    #main {
        width: 100% !important;

        &.active {
            .form-footer {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .overlay.active {
        display: block;
        opacity: 1;
    }

    #menu-close-button {
        display: inline-block;
    }
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: $zindex-menu-mobile-overlay;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
