.section {
    box-shadow: 0 2px 3px 0 rgba($gray-700, .25);
    &:not(:last-of-type) {
        @include margin-bottom; // Variable bottom margin
    }
}

.section-row {
    &:not(:last-of-type) {
        @include margin-bottom; // Variable bottom margin
    }
}

.section-header {
    background-color: $gray-700;
    color: $white;
    @include padding-x-main;
    padding-top: ($spacer * .375);
    padding-bottom: ($spacer * .375);
    @include media-breakpoint-up(lg) {
        padding-top: ($spacer * .5);
        padding-bottom: ($spacer * .5);
    }
    display: flex; // next three lines handle additional elements like forms or buttons
    align-items: center;
    justify-content: space-between;
    .section-title {
        @extend .h3;
        margin-bottom: 0 !important;
        line-height: 31px;
    }
    //form,
    .form-group {
        @extend .form-inline;
        margin-bottom: 0;
        label {
            padding-right: 1rem;
        }
        // TODO The css-2b097c-container is bad as the numbers will continuously
        //  change within React Select.
        .input-group, .css-2b097c-container {
            min-width: 15rem;
            color: $gray-900;
        }
    }
    &-tabs { // tabs replace the standard header
        padding-top: ($spacer * .5);
        @include padding-x-main;
        padding-bottom: 0;
    }
}

// tabs stacked after standard header
.section-header + .section-header {
    &-tabs {
        background-color: $gray-500;
        .nav-link {
            background-color: $gray-300;
            @include hover-focus() {
                background-color: $gray-200;
                color: $gray-700;
            }
        }
        .nav-link.active,
        .nav-item.show .nav-link {
            color: $nav-tabs-link-active-color;
            background-color: $white;
            border-color: none;
        }
    }
}

.section-body {
    background-color: $white;
    @include padding-main;
    flex-grow: 1;
    &-snug {
        padding-top: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
    }
    .section-header {
        background-color: $gray-500;
        .section-title {
            @extend .h4;
        }
    }
    &>.section-header {
        margin-bottom: 1rem;
    }
}

// Adjust spacing when accordions are within a .section
.section-body {
    &.accordion {
        padding: 0 !important;
        margin-bottom: 0.25rem;
        .card {
            border: 0;
            .card-header {
                border: 0;
                padding: 0;
                .header-link {
                    display: block;
                    color:  $gray-900;
                    text-align: left;
                    background: $white;
                    padding: 20px;
                    cursor: pointer;
                    &::after {
                        content: "\f107";
                        font-family: 'Font Awesome 5 Pro', sans-serif;
                        font-weight: 400 !important;
                        float: right;
                    }
                    &.collapsed::after {
                        content: "\f106";
                    }
                }
            }
            .card-body {
                padding: 0 1rem 1rem 1rem;
                background: $white;
            }
        }
    }
}

.section-footer {
    background-color: $white;
    @include padding-main;
    padding-top: 0 !important;
}

/*
.section-row {
    .section {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
*/

.equal-height {
    > div[class*="col"] { // select immediate children div with class starting with 'col-'
        flex-grow: 1;
        // This selector may need to be revised if too vague
        > :first-child { // styles apply only to the immediate children of div class="col-"
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

