// Variables, these will override any variables declared in core files below
@import "variable_overrides";

// Third party libraries
@import '~react-toastify/dist/ReactToastify.min.css';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/regular";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~rc-steps/assets/index.css";
@import "~react-input-range/src/scss/index.scss";

// Bootstrap core
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
// Note we include our own Mixin overrides in the mix so we can have some more fine tuned control!
@import "mixins_overrides";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Components
@import 'components/background_blocks';
@import 'components/badge';
@import 'components/bootstrap_money';
@import 'components/button_styles';
@import 'components/detail_list';
@import 'components/flex_table';
@import 'components/form_styles';
@import 'components/hubspot';
@import 'components/menu';
@import 'components/modal_styles';
@import 'components/multiselect_styles';
@import 'components/panel_styles';
@import 'components/placeholders';
@import 'components/rc_tree';
@import 'components/sections';
@import 'components/sidebar';
@import 'components/styles';
@import 'components/table_styles';
@import 'components/toasts';
@import 'components/typeahead_styles';
@import 'components/typeahead_tagging';
@import 'components/typography';
@import 'components/date_picker';

// Pages
@import 'pages/inventory';
@import 'pages/tour_planner';

// Layout
@import 'layout/layout';

