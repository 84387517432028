.datepicker-container {
  .bootstrap-datetimepicker-widget {
    .btn {
      height: auto !important;
      width: 100% !important;
      text-align: center;
      span, i{
        float: none !important;
        padding-top: 0 !important;
      }
    }

    tfoot th,
    tfoot td,
    thead th,
    thead td,
    tbody th,
    tbody td {
      padding: inherit !important;
    }

    thead th {
      color: black !important;
    }
  }

  .form-group {
    .form-control {
      width: 1% !important;
    }
    .input-group-addon {
      .input-group-text {
        border: 1px solid $gray-medium;
        height: calc(1.5em + 0.75rem + 2px);
      }
    }
  }
}

.modal {
  .datepicker-container {
    i {
      margin: 0;
    }
  }
}


.input-group.date .input-group-text {
  border-radius: 0 !important;
  padding: 0.625rem 0.75rem !important;
}


.datepicker-full-width {
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 100%;
    }
}

.react-datepicker-popper {
    z-index: 1020;
}

.react-datepicker-wrapper {
    width: 100%;
    & .react-datepicker__close-icon {
        padding-right: 60px;
        padding-top: 10px;
    }
}
