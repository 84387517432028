// Add a light pill that switches up some of the color assignments
// compared to the default theme colors set in base Bootstrap and
// it's default mixins.
.badge-light {
    &.badge-primary,
    &.badge-success {
        background-color: $green-200;
        color: $green-700;
    }
    &.badge-warning {
        background-color: $orange-200;
        color: $orange-700;
    }
    &.badge-secondary {
        background-color: $gray-200;
        color: $gray-700;
    }
    &.badge-info {
        background-color: $cyan-200;
        color: $cyan-700;
    }
    &.badge-danger {
        background-color: $red-200;
        color: $red-700;
    }
}

td {
    .badge {
        font-size: $font-size-sm;
    }
}
