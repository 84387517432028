/* containers */
.container-border {
  border: 1px solid theme-color("dark");
  padding: 15px 20px;
}

.center-row {
  margin-left: 15%;
  margin-right: 15%;
}

.col-select-hub {
  text-align: center;
  padding: 2%;

  p {
    margin-top: 5%;
  }
}

/* margins and padding */
.ml-25 {
  margin-left: 25px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-50 {
  margin-right: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}


/* font sizes */
.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

/* Icons */
.expand-arrow {

  font-size: 20px;
  cursor: pointer;

}

/* Headers */
.page-header {
  font-size: 18px;
  border-bottom: 1px solid theme-color("light");
  margin-bottom: 20px;
  margin-top: 50px;
  color: theme-color("dark");


  .back-arrow {
    color: theme-color("dark");
    margin-right: 1rem;

    &:hover {
      color: theme-color("dark");
    }
  }

  span.right-text-link {
    cursor: pointer;
    i{
      color: theme-color("warning");
      margin-left: 1em;
    }
  }

  p {
    margin-bottom: 1em;
  }
}

.sub-header {

  border-bottom: 1px solid #F2EFEF;
  padding-bottom: 10px;
  width: 50%;
}

/* Navigation */
.nav-tabs {
    border-bottom: none;

    .nav-item{
        margin-bottom: 0;
    }

    .nav-link {
        // margin-bottom: -$nav-tabs-border-width;
        margin-bottom: 0;
        border: $nav-tabs-border-width solid transparent;
        @include border-top-radius($nav-tabs-border-radius);

        @include hover-focus() {
            border-color: $nav-tabs-link-hover-border-color;
        }

        &.disabled {
            color: $nav-link-disabled-color;
            background-color: transparent;
            border-color: transparent;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $nav-tabs-link-active-color;
        background-color: $nav-tabs-link-active-bg;
        border-color: $nav-tabs-link-active-border-color;
        @include border-top-radius($nav-tabs-border-radius);
    }

    .dropdown-menu {
        // Make dropdown border overlap tab border
        margin-top: -$nav-tabs-border-width;
        // Remove the top rounded corners here since there is a hard edge above the menu
        @include border-top-radius(0);
    }
}

.nav-tabs-header {

    .nav-item {
        margin-right: 0.25rem;
    }
    .nav-link {
        margin-bottom: 0;
        border: none;
        background-color: $gray-500;
        font-weight: $font-weight-bold;
        color: $white;
        @include border-top-radius($nav-tabs-border-radius);

        @include hover-focus() {
            background-color: $gray-300;
        }
        &.disabled {
            color: $white;
            padding-left: 0 !important;
            background-color: transparent !important;
            border-color: transparent !important;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $nav-tabs-link-active-color;
        background-color: $white;
        border: none;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
        font-size: $font-size-sm;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

.options-list {
  margin-top: 60px;
}

/* Alerts */
//The alert box is where all of the alert types will appear (success, wanring, info). These alerts are rendered
//in the box on the master template blade when a view is returned with errors, success or info or if a route is redirected back with
//errors, etc.
//The box alerts will always appear in a static position in the top right corner of the screen.
//They will not push or affect any other content on the page
//The width is responsive with screen width
.alert-box {
  position: absolute;
  top: 3rem;
  right: 1.5%;
  z-index: 100;
  width: 473px;
  max-width: 473px;

  .alert {
    width: 100%;
  }

}

.alert {

  z-index: 10;
  font-weight: $font-weight-bold;
  float: right;
  background-color: white;
  border: 1px solid;
  border-radius: 0%;

  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);

  margin: 5px 10px 5px;

  i {
    font-size: 22px;
    position: relative;
    left: -15px;
    top: -7px;
    float: left;

  }

  span {
    display: block;
    overflow: hidden;
    font-size: 14px;

  }
}

.alert-success {
  color: theme-color("success");
  border-color: theme-color("success");
}

.alert-danger {
  color: theme-color("danger");
  border-color: theme-color("danger");
}

.alert-warning {
  color: theme-color("warning");
  border-color: theme-color("warning");
}

.alert-info {
  color: theme-color("info");
  border-color: theme-color("info");
}

.alert-dismissible .close {
  &:focus {
    outline: none;
  }

  position: absolute;
  top: -10px;
  right: -15px;

  span {
    font-size: 18px;
  }

  color: inherit;
}

/*lists*/
.list-shadow {
  -webkit-box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.20);
  -moz-box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.20);
  box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.20);
}

.breadcrumb {
    background-color: unset;
    padding: 0;
    font-size: 0.875rem;
    .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
    }
    .breadcrumb-item {
        a {
            text-decoration: underline;
        }
    }
}

/* Mapbox styles */
#map {
  top: 0;
  bottom: 0;
  width: 100%;
  height: 500px;
}

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

/* Progress Display Bar Styles */
.progress-display-bar {
    // This is a copy and paste from the internal Bootstrap scss file.
    .progress-bar-label {
        @include transition($progress-bar-transition);
    }

    .progress {
        height: 40px;
        border-radius: 0;
        font-weight: $font-weight-bolder;
        .progress-bar {
            text-align: left;
            span {
                padding-left: 16px;
            }
        }
    }

    .progress-bar {
        color: $gray-900;
        &[class$="900"],
        &[class$="800"],
        &[class$="700"],
        &[class$="600"],
        &[class$="500"] {
            color: $white;
        }
    }
}

/* lists */

//packing list (order fulfillment)
.packing-list, .receiving-list {

    .list-group-item {
        color: $gray-900;
        font-size: $font-size-sm;
        border: none;
        padding: ($spacer * .5) $spacer;
        &:not(:last-child) {
            border-bottom: 1px solid $gray-100;
        }
        @include hover() {
            color: $table-hover-color;
            background-color: $table-hover-bg;
        }
        &.active {
            background-color: $green-100;
            border-color: $green-100;
        }
        &.disabled {
            &:hover {
                background-color: $white;
            }
        }
    }
}

.contain-img {
    object-fit: contain;
    height: 128px;
}
