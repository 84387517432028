.detail-list {
    display: table;
    font-size: $font-size-sm;
    .row {
        display: table-row;
    }
    .col {
        display: table-cell;
        width: auto;
        padding-left: 0;
        padding-right: 0;
        &.row-label, {
            padding: 0.125rem 1rem 0.125rem 0;
            color: $gray-500;
            span {
                white-space: nowrap; // NEW
            }
        }
        &.row-value {
            padding: 0.125rem 1rem 0.125rem 0;
            width: 100%;
        }
    }

    .row-span {
        position: absolute;
        width: 100%;
    }
}
