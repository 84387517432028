.modal-full{
  max-width: 90%;
}

.extra-actions-forms-modal {
    .modal-content {
        overflow: visible;
    }
}

.modal {
    .error {
        color: theme-color("danger");
    }
    .info {
        color: theme-color("warning");
    }
    .success {
        color: theme-color("success");
    }
    &-header {
        padding: $modal-header-padding;
    }
    &-title {
        //@include header-line;
        .h4 {
            font-size: $h4-font-size;
        }
    }
    &-content {
        padding: 0;
        overflow: visible;
    }
    &.coloured-bg {
        .modal-content {
            background-color: $eggplant-lightest;
        }
    }
    &-body-full-width {
        padding: 0;
        .detail-row {
            font-size: 0.8rem;
        }
    }
    .form-footer {
        width: 100% !important;
        margin-left: 0 !important;
    }
    // TODO - clean up tp use dynamic classes 
    .p-main-condensed + .form-footer {
        padding-left: $padding-main-compressed;
        padding-right: $padding-main-compressed;
    }
}
