.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: $gray-300;
    opacity: $placeholder-opacity-max;
    margin-right: 15px;
    padding: 0 !important;

    &.btn::before {
        display: inline-block;
        content: "";
    }
}

// Sizing
.placeholder-xs {
    min-height: .6em;
}

.placeholder-sm {
    min-height: .8em;
}

.placeholder-lg {
    min-height: 1.2em;
}

// Animation
.placeholder-wave {
    mask-image: linear-gradient(130deg, $black 55%, rgba(0, 0, 0, (1 - $placeholder-opacity-min)) 75%, $black 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
    100% {
        mask-position: -200% 0%;
    }
}
